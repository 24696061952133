// Ensure MyForm receives certUrl as a prop and includes it in the form data

import React, { useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import debounce from "lodash.debounce";

const MyForm = ({
    step,
    done,
    errors,
    handleBack,
    handleNext,
    setValue,
    trigger,
    getValues,
    register,
    watch,
    control,
    handlePhoneChange,
    addToDataLayer,
    setZipCode,
    isValid,
    zipCode,
    isZipValid,
    canSubmit,
    setError,
    clearErrors,
    handleCoverageNext,
    handleGenderNext,
    handleConditionsNext,
    onSubmit,
    loading,
    certUrl
}) => {

    useEffect(() => {
        console.log("Updated certUrl", certUrl);
    }, [certUrl]);

    return (
        <form onSubmit={(e) => { e.preventDefault(); onSubmit(); }} onKeyDown={(e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        }}>
            {loading && (
                <div className="form-loading">
                    <img
                        src="https://uploads-ssl.webflow.com/65757661e64ae8bdcd14c87d/65ae4f3386c1ef82adb75b7f_loader.svg"
                        alt="loading"
                    />
                </div>
            )}

            {step === 0 && !done && (
                <div className="step">
                    <div className="step-body">
                        <h3 className="step-title">What is your zip code?</h3>
                        <div className="step-fields-line">
                            <div>
                                <input
                                    className="step-fields-line-field"
                                    type="text"
                                    name="zip-code"
                                    id="zipcode"
                                    maxLength={5}
                                    placeholder="Zip Code"
                                    {...register("zipCode", {
                                        required: true,
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: 'Please enter a valid zip code',
                                        },
                                        onChange: (e) => setZipCode(e.target.value),
                                        onBlur: (e) => {
                                            addToDataLayer(
                                                "Zip Code Entry",
                                                "Form",
                                                "Zip Code",
                                                "Form-Interaction",
                                                e.target.value
                                            );
                                        },
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="step-footer">
                        <div className="step-action">
                            <button
                                type="button"
                                className={`next ${isValid &&
                                    !errors.zipCode &&
                                    zipCode.length === 5 &&
                                    isZipValid
                                    ? "valid"
                                    : ""
                                    }`}
                                onClick={() => {
                                    if (isZipValid) {
                                        handleNext(["zipCode"]);
                                        addToDataLayer(
                                            "Zip Code Complete",
                                            "Form",
                                            "Zip Code Complete",
                                            "Form-Interaction",
                                            getValues("zipCode"),
                                        );
                                    }
                                }}
                            >
                                Next
                            </button>
                        </div>
                        {errors.zipCode && (
                            <div className="step-note">
                                {errors.zipCode && errors.zipCode.message}
                                {!errors?.zipCode?.message && "Please fill all fields"}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {step === 1 && !done && (
                <div className="step">
                    <div className="step-body">
                        <h3 className="step-title">
                            Are you looking for individual or family coverage?
                        </h3>
                        <div className="step-fields-line">
                            <label
                                className="step-fields-line-field"
                                htmlFor="individual"
                            >
                                <input
                                    type="radio"
                                    id="individual"
                                    name="coverage"
                                    value="individual"
                                    {...register("coverage", {
                                        required: true,
                                    })}
                                    onChange={(e) => {
                                        console.log("Coverage Selected: ", e.target.value);
                                        setValue("coverage", e.target.value);
                                        trigger("coverage");
                                        addToDataLayer(
                                            "Coverage Type Select",
                                            "Form",
                                            "Coverage Type Select",
                                            "Form-Interaction",
                                            e.target.value
                                        );
                                    }}
                                    onBlur={(e) => {
                                        addToDataLayer(
                                            "Coverage Type Blur",
                                            "Form",
                                            "Coverage Type Blur",
                                            "Form-Interaction",
                                            e.target.value
                                        );
                                    }}
                                />
                                <span className="radio"></span>
                                Individual
                            </label>
                            <label className="step-fields-line-field" htmlFor="family">
                                <input
                                    type="radio"
                                    id="family"
                                    name="coverage"
                                    value="family"
                                    {...register("coverage", {
                                        required: true,
                                    })}
                                    onChange={(e) => {
                                        console.log("Coverage Selected: ", e.target.value);
                                        setValue("coverage", e.target.value);
                                        trigger("coverage");
                                        addToDataLayer(
                                            "Coverage Type Select",
                                            "Form",
                                            "Coverage Type Select",
                                            "Form-Interaction",
                                            e.target.value
                                        );
                                    }}
                                    onBlur={(e) => {
                                        addToDataLayer(
                                            "Coverage Type Blur",
                                            "Form",
                                            "Coverage Type Blur",
                                            "Form-Interaction",
                                            e.target.value
                                        );
                                    }}
                                />
                                <span className="radio"></span>
                                Family
                            </label>
                        </div>
                    </div>
                    <div className="step-footer">
                        <div className="step-action">
                            <button type="button" className="back" onClick={handleBack}>
                                Back
                            </button>
                            <button
                                type="button"
                                onClick={handleCoverageNext}
                                className={`next ${isValid ? "valid" : ""}`}
                            >
                                Next
                            </button>
                        </div>
                        {errors["coverage"] && (
                            <div className="step-note">Please make your choice</div>
                        )}
                    </div>
                </div>
            )}

            {step === 2 && !done && (
                <div className="step">
                    <div className="step-body">
                        <h3 className="step-title">What is your date of birth?</h3>
                        <div className="step-fields-line">
                            <div>
                                <Controller
                                    name="dob"
                                    control={control}
                                    rules={{
                                        required: "Date of Birth is required",
                                        pattern: {
                                            value:
                                                /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
                                            message: "Date of Birth must be in MM/DD/YYYY format",
                                        },
                                    }}
                                    render={({ field }) => (
                                        <InputMask
                                            mask="99/99/9999"
                                            maskplaceholder="mm/dd/YYYY"
                                            placeholder="mm/dd/YYYY"
                                            {...field}
                                            className="step-fields-line-field"
                                            onBlur={(e) => {
                                                addToDataLayer(
                                                    "DOB Entry",
                                                    "Form",
                                                    "DOB",
                                                    "Form-Interaction",
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="step-footer">
                        <div className="step-action">
                            <button type="button" className="back" onClick={handleBack}>
                                Back
                            </button>
                            <button
                                type="button"
                                className={`next ${isValid ? "valid" : ""}`}
                                onClick={() => {
                                    handleNext(["dob"]);
                                    addToDataLayer(
                                        "DOB Complete",
                                        "Form",
                                        "DOB Complete",
                                        "Form-Interaction",
                                        getValues("dob")
                                    );
                                }}
                            >
                                Next
                            </button>
                        </div>
                        {errors.dob && (
                            <div className="step-note">{errors.dob.message}</div>
                        )}
                    </div>
                </div>
            )}

            {step === 3 && !done && (
                <div className="step">
                    <div className="step-body">
                        <h3 className="step-title">
                            What is your expected 2023 household income?
                        </h3>
                        <div className="step-fields-line">
                            <div>
                                <select
                                    name="household"
                                    id="household"
                                    className="step-fields-line-field"
                                    {...register("household", {
                                        required: true,
                                    })}
                                    onInput={(e) => {
                                        addToDataLayer(
                                            "Income Select",
                                            "Form",
                                            "Income Select",
                                            "Form-Interaction",
                                            e.target.value
                                        );
                                    }}
                                >
                                    <option value="" disabled="" defaultValue>
                                        Select option
                                    </option>
                                    <option value="Below $16,000">Below $16,000</option>
                                    <option value="$17,000 - $29,999">
                                        $17,000 - $29,999
                                    </option>
                                    <option value="$30,000 - $49,999">
                                        $30,000 - $49,999
                                    </option>
                                    <option value="$50,000 - $74,999">
                                        $50,000 - $74,999
                                    </option>
                                    <option value="$75,000 - $99,999">
                                        $75,000 - $99,999
                                    </option>
                                    <option value="Over $100,000">Over $100,000</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="step-footer">
                        <div className="step-action">
                            <button type="button" className="back" onClick={handleBack}>
                                Back
                            </button>
                            <button
                                type="button"
                                className={`next ${isValid ? "valid" : ""}`}
                                onClick={() => {
                                    handleNext(["household"]);
                                    addToDataLayer(
                                        "Income Complete",
                                        "Form",
                                        "Income Complete",
                                        "Form-Interaction",
                                        getValues("household")
                                    );
                                }}
                            >
                                Next
                            </button>
                        </div>
                        {errors.household && (
                            <div className="step-note">Please select an option</div>
                        )}
                    </div>
                </div>
            )}

            {step === 4 && !done && (
                <div className="step">
                    <div className="step-body">
                        <h3 className="step-title">What is your gender?</h3>
                        <div className="step-fields-line">
                            <label className="step-fields-line-field" htmlFor="male">
                                <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    {...register("gender", {
                                        required: true,
                                    })}
                                    value="male"
                                    onChange={(e) => {
                                        setValue("gender", e.target.value); // Explicitly set value
                                        trigger("gender");
                                        addToDataLayer(
                                            "Gender Select",
                                            "Form",
                                            "Gender Select",
                                            "Form-Interaction",
                                            e.target.value
                                        );
                                    }}
                                    onBlur={(e) => {
                                        addToDataLayer(
                                            "Gender Blur",
                                            "Form",
                                            "Gender Blur",
                                            "Form-Interaction",
                                            e.target.value
                                        );
                                    }}
                                />
                                <span className="radio"></span>
                                Male
                            </label>
                            <label className="step-fields-line-field" htmlFor="female">
                                <input
                                    type="radio"
                                    id="female"
                                    name="gender"
                                    {...register("gender", {
                                        required: true,
                                    })}
                                    value="female"
                                    onChange={(e) => {
                                        setValue("gender", e.target.value); // Explicitly set value 
                                        trigger("gender");
                                        addToDataLayer(
                                            "Gender Select",
                                            "Form",
                                            "Gender Select",
                                            "Form-Interaction",
                                            e.target.value
                                        );
                                    }}
                                    onBlur={(e) => {
                                        addToDataLayer(
                                            "Gender Blur",
                                            "Form",
                                            "Gender Blur",
                                            "Form-Interaction",
                                            e.target.value
                                        );
                                    }}
                                />
                                <span className="radio"></span>
                                Female
                            </label>
                        </div>
                    </div>
                    <div className="step-footer">
                        <div className="step-action">
                            <button type="button" className="back" onClick={handleBack}>
                                Back
                            </button>
                            <button
                                type="button"
                                className={`next ${isValid ? "valid" : ""}`}
                                onClick={handleGenderNext}
                            >
                                Next
                            </button>
                        </div>
                        {errors.gender && (
                            <div className="step-note">Please make your choice</div>
                        )}
                    </div>
                </div>
            )}

            {step === 5 && !done && (
                <div className="step">
                    <div className="step-body">
                        <h3 className="step-title">
                            Do you have any of the following conditions:
                        </h3>
                        <h4 className="step-subtitle">
                            Cancer, Heart Attack, Stroke, Diabetes, AIDS/HIV, Pulmonary
                            Disease
                        </h4>
                        <div className="step-fields-line">
                            <label
                                className="step-fields-line-field"
                                htmlFor="condition-yes"
                            >
                                <input
                                    type="radio"
                                    id="condition-yes"
                                    name="conditions"
                                    {...register("conditions", {
                                        required: true,
                                    })}
                                    value="yes"
                                    onChange={(e) => {
                                        setValue("conditions", e.target.value); // Explicitly set value
                                        trigger("conditions");
                                        addToDataLayer(
                                            "Conditions Select",
                                            "Form",
                                            "Conditions Select",
                                            "Form-Interaction",
                                            e.target.value
                                        );
                                    }}
                                    onBlur={(e) => {
                                        addToDataLayer(
                                            "Conditions Blur",
                                            "Form",
                                            "Conditions Blur",
                                            "Form-Interaction",
                                            e.target.value
                                        );
                                    }}
                                />
                                <span className="radio"></span>
                                Yes
                            </label>
                            <label
                                className="step-fields-line-field"
                                htmlFor="condition-no"
                            >
                                <input
                                    type="radio"
                                    id="condition-no"
                                    name="conditions"
                                    {...register("conditions", {
                                        required: true,
                                    })}
                                    value="no"
                                    onChange={(e) => {
                                        setValue("conditions", e.target.value); // Explicitly set value
                                        trigger("conditions");
                                        addToDataLayer(
                                            "Conditions Select",
                                            "Form",
                                            "Conditions Select",
                                            "Form-Interaction",
                                            e.target.value
                                        );
                                    }}
                                    onBlur={(e) => {
                                        addToDataLayer(
                                            "Conditions Blur",
                                            "Form",
                                            "Conditions Blur",
                                            "Form-Interaction",
                                            e.target.value
                                        );
                                    }}
                                />
                                <span className="radio"></span>
                                No
                            </label>
                        </div>
                    </div>
                    <div className="step-footer">
                        <div className="step-action">
                            <button type="button" className="back" onClick={handleBack}>
                                Back
                            </button>
                            <button
                                type="button"
                                className={`next ${isValid ? "valid" : ""}`}
                                onClick={handleConditionsNext}
                            >
                                Next
                            </button>
                        </div>
                        {errors.conditions && (
                            <div className="step-note">Please make your choice</div>
                        )}
                    </div>
                </div>
            )}

            {step === 6 && !done && (
                <div className="step">
                    <div className="step-body">
                        <h3 className="step-title">What is your name?</h3>
                        <div className="step-fields-line">
                            <div>
                                <input
                                    className="step-fields-line-field"
                                    type="text"
                                    name="first-name"
                                    id="name"
                                    placeholder="First Name"
                                    {...register("firstName", {
                                        required: true,
                                        pattern: {
                                            value: /^[A-Za-z]+$/,
                                            message:
                                                'The "First Name" field should only contain letters. Please revise your entry.',
                                        },
                                        onBlur: (e) => {
                                            addToDataLayer(
                                                "First Name Entry",
                                                "Form",
                                                "First Name",
                                                "Form-Interaction",
                                                e.target.value
                                            );
                                        },
                                    })}
                                />
                            </div>
                            <div>
                                <input
                                    className="step-fields-line-field"
                                    type="text"
                                    name="last-name"
                                    id="surname"
                                    placeholder="Last Name"
                                    {...register("lastName", {
                                        required: true,
                                        pattern: {
                                            value: /^[A-Za-z]+$/,
                                            message:
                                                'The "Last Name" field should only contain letters. Please revise your entry.',
                                        },
                                        onBlur: (e) => {
                                            addToDataLayer(
                                                "Last Name Entry",
                                                "Form",
                                                "Last Name",
                                                "Form-Interaction",
                                                e.target.value
                                            );
                                        },
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="step-footer">
                        <div className="step-action">
                            <button type="button" className="back" onClick={handleBack}>
                                Back
                            </button>
                            <button
                                type="button"
                                className={`next ${isValid ? "valid" : ""}`}
                                onClick={() => {
                                    handleNext(["firstName", "lastName"]);
                                    addToDataLayer(
                                        "Name Complete",
                                        "Form",
                                        "Name Complete",
                                        "Form-Interaction",
                                        getValues(["firstName", "lastName"])
                                    );
                                }}
                            >
                                Next
                            </button>
                        </div>
                        {(errors.firstName || errors.lastName) && (
                            <div className="step-note">
                                {errors.firstName && errors.firstName.message}
                                {errors.lastName && errors.lastName.message}
                                {!errors?.firstName?.message &&
                                    !errors?.lastName?.message &&
                                    "Please fill all fields"}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {step === 7 && !done && (
                <div className="step">
                    <div className="step-body">
                        <h3 className="step-title">What is your phone number?</h3>
                        <div className="step-fields-line">
                            <div>
                                <Controller
                                    name="phone"
                                    control={control}
                                    render={({ field: { name, value, onChange } }) => (
                                        <InputMask
                                            name="phoneInput"
                                            mask="+1 (999) 999-9999"
                                            value={value || ""}
                                            onChange={handlePhoneChange(onChange)}
                                            onBlur={() => {
                                                addToDataLayer(
                                                    "Phone Number",
                                                    "Form",
                                                    "Phone Number",
                                                    "Form-Interaction",
                                                    value || ""
                                                );
                                            }}
                                        >
                                            {(inputProps) => (
                                                <input
                                                    {...inputProps}
                                                    name={name}
                                                    placeholder="+1 (000) 000 0000"
                                                    required
                                                    className="step-fields-line-field"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                            </div>
                        </div>
                        <label htmlFor="agree" className="submit-note">
                            <div className="input-box">
                                <input
                                    type="checkbox"
                                    name="agree"
                                    id="agree"
                                    {...register("agree", {
                                        required: true,
                                    })}
                                    onInput={(e) => {
                                        trigger("agree");
                                        addToDataLayer(
                                            "Terms Accept",
                                            "Form",
                                            "Terms Accepted",
                                            "Form-Interaction",
                                            e.target.checked
                                        );
                                    }}
                                />
                                <span className="checkbox"></span>
                            </div>
                            <span>
                                By checking this box you agree to Quality Affordable Care’s{" "}
                                <a
                                    href="https://www.qualityaffordablecare.com/terms-conditions"
                                    target="_blank"
                                >
                                    Terms and Conditions
                                </a>{" "}
                                and{" "}
                                <a
                                    href="https://www.qualityaffordablecare.com/privacy-policy"
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                                , and to receive automated text messages and calls from
                                Quality Affordable Care and other GetScale Affiliated
                                Brands, related to this inquiry and other offers or
                                information. Your consent is not required to purchase any
                                product or service. Message and data rates may apply. Reply
                                STOP to opt out. Messaging frequency may vary. Reply HELP
                                for further assistance
                            </span>
                        </label>
                    </div>
                    <div className="step-footer">
                        <div className="step-action">
                            <button type="button" className="back" onClick={handleBack}>
                                Back
                            </button>
                            <button
                                type="submit"
                                className={`next ${isValid && canSubmit ? "valid" : ""}`}
                                onClick={() => {
                                    handleNext(["phone", "agree"]);
                                    addToDataLayer(
                                        "Form Submit",
                                        "Form",
                                        "Form-Submit",
                                        "Form-Interaction",
                                        [
                                            ...getValues([
                                                "coverage",
                                                "age",
                                                "household",
                                                "gender",
                                                "conditions",
                                                "firstName",
                                                "lastName",
                                                "agree",
                                            ]),
                                            getValues("phone")
                                        ]
                                    );
                                }}
                            >
                                Get a quote
                            </button>
                        </div>
                        {errors.agree && (
                            <div className="step-note">Please agree to the terms</div>
                        )}
                    </div>
                </div>
            )}

            {done && (
                <div className="step">
                    <div className="step-body">
                        <div className="icon-box" style={{ margin: "0 auto 2.5rem" }}>
                            <img
                                src="https://uploads-ssl.webflow.com/65757661e64ae8bdcd14c87d/65ae4f331d4812f6ca0b7b2a_heart-icon.svg"
                                alt="like"
                            />
                        </div>
                        <h3 className="step-title">Thank You for Requesting a Quote</h3>
                        <p>
                            One of our licensed insurance agent will contact you shortly!
                        </p>
                    </div>
                </div>
            )}
            <input type="hidden" name="xxTrustedFormCertUrl" id="xxTrustedFormCertUrl_0" value={certUrl} />
        </form>
    );
};

export default MyForm;
