import React, { useEffect, useState } from 'react';

const TrustedFormScript = ({ setCertUrl }) => {

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://api.trustedform.com/trustedform.js?provide_referrer=false&field=xxTrustedFormCertUrl';
        document.body.appendChild(script);

        script.onload = () => {
            const interval = setInterval(() => {
                const certElement = document.getElementById('xxTrustedFormCertUrl_0');
                if (certElement && certElement.value) {
                    setCertUrl(certElement.value);
                    clearInterval(interval);
                }
            }, 100);
        };

        return () => {
            document.body.removeChild(script);
        };
    }, [setCertUrl]);

    return null;
};

export default TrustedFormScript;
