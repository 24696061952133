// Ensure certUrl is passed to MyForm as a prop and used in the onSubmit function

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import debounce from "lodash.debounce";
import { createHmac } from "crypto";
import "./App.scss";
import TrustedFormScript from "./TrustedFormScript";
import MyForm from "./MyForm";

window.dataLayer = window.dataLayer || [];

async function postSecureData(url, data, secret) {
  const dataJSON = JSON.stringify(data);
  const hash = createHmac("sha256", secret).update(dataJSON).digest("base64");

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "gsi-signature": `gsi-${hash}`,
      "Client-Id": "aca-lander",
    },
    body: dataJSON,
  });
}

function getAllUrlParams(decode = true) {
  const queryString = window.location.search.slice(1);
  if (!queryString) {
    return {};
  }

  const params = {};
  const pairs = queryString.split("&");

  for (let i = 0; i < pairs.length; i++) {
    let [key, value] = pairs[i].split("=");
    if (decode) {
      key = decodeURIComponent(key);
      value = decodeURIComponent(value);
    }

    if (params[key]) {
      params[key] = Array.isArray(params[key]) ? params[key] : [params[key]];
      params[key].push(value);
    } else {
      params[key] = value;
    }
  }

  return params;
}

const SECRET = "DM1J5MKQZkKGzCYzE9KlW";

function addToDataLayer(event, category, action, label, value) {
  window.dataLayer.push({
    event,
    eventProps: {
      category,
      action,
      label,
      value,
    },
  });
}

function App() {
  const stepsLength = 7;
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [certUrl, setCertUrl] = useState("");

  const handleBack = () => {
    setStep((s) => s - 1);
  };

  const handleNext = async (fields) => {
    const isStepValid = await trigger(fields);
    console.log("Current Values: ", getValues()); // Debugging statement
    isStepValid && step < stepsLength && setStep((s) => s + 1);
  };

  const zip_msg =
    "Please enter a valid zip code so that we may better assist you.";

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      phone: "",
      coverage: "",
      dob: "",
    },
  });

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    control,
    getValues,
    setError,
    setValue,
    clearErrors,
  } = methods;

  const [zipCode, setZipCode] = useState("");

  const validateZipCode = async (zip) => {
    if (zip.length < 5) return false;
    try {
      const response = await fetch(
        `https://us-west1-getsaleswarehouse.cloudfunctions.net/zip-code-validation-api?zip_code=${zip}`
      );
      const data = await response.json();
      return data.valid;
    } catch (error) {
      return true;
    }
  };

  const [isZipValid, setIsZipValid] = useState(false);

  const debouncedValidateZip = debounce(async (zip) => {
    if (zip.length < 5) return;
    const isValid = await validateZipCode(zip);
    if (!isValid) {
      setError("zipCode", {
        type: "manual",
        message: zip_msg,
      });
    } else {
      setIsZipValid(true);
      clearErrors("zipCode");
    }
  }, 200);

  useEffect(() => {
    setIsZipValid(false);

    if (zipCode) {
      debouncedValidateZip(zipCode);
    }

    return () => {
      debouncedValidateZip.cancel();
    };
  }, [zipCode]);

  const [urlParams, setUrlParams] = useState(getAllUrlParams());

  const onSubmit = (data) => {
    console.log(certUrl);
    setLoading(true);
    const modifiedData = {
      ...data,
      phone: data.phone || "+1 (000) 000 0000",
      ...urlParams,
      trustedform: certUrl,
    };
    postSecureData(
      "https://us-west1-getsaleswarehouse.cloudfunctions.net/gsi-form-submission",
      modifiedData,
      SECRET
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setDone(true);
      })
      .catch((err) => {
        setLoading(false);
        setDone(true);
      });
    console.log(window.dataLayer);
    console.log("MODIFIED DATA HERE:", modifiedData);
  };

  const [formattedPhoneNum, setFormattedPhoneNum] = useState("");

  const handlePhoneChange = (onChange) => (e) => {
    const formattedPhone = e.target.value.replace(/[^\d]/g, "");
    onChange(formattedPhone);
    setFormattedPhoneNum(formattedPhone);
    if (formattedPhone?.length === 10) setCanSubmit(true);
  };

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location,
        title: document.title,
      },
    });
  }, []);

  const handleCoverageNext = () => {
    const coverageValue = getValues("coverage");
    console.log("Coverage Value Before Next: ", coverageValue);
    handleNext(["coverage"]);
    addToDataLayer(
      "Coverage Type Complete",
      "Form",
      "Coverage Type Complete",
      "Form-Interaction",
      coverageValue
    );
    console.log("Next Clicked, Coverage: ", coverageValue);
  };

  const handleGenderNext = () => {
    const genderValue = getValues("gender");
    handleNext(["gender"]);
    addToDataLayer(
      "Gender Complete",
      "Form",
      "Gender Complete",
      "Form-Interaction",
      genderValue
    );
  };

  const handleConditionsNext = () => {
    const conditionsValue = getValues("conditions");
    handleNext(["conditions"]);
    addToDataLayer(
      "Conditions Complete",
      "Form",
      "Conditions Complete",
      "Form-Interaction",
      conditionsValue
    );
  };

  const handleCertUrlNext = () => {
    const certValue = getValues("certUrl");
    handleNext(["certUrl"]);
    addToDataLayer("certUrl", "web", "web", "web", certValue);
  };

  return (
    <>
      <TrustedFormScript setCertUrl={setCertUrl} />
      <div className="step-form-box">
        <div className="step-form-box-progress">
          <div
            className="step-form-box-progress-bar"
            style={{ width: `${(100 / stepsLength) * step}%` }}
          ></div>
        </div>
        <MyForm
          step={step}
          done={done}
          errors={errors}
          handleBack={handleBack}
          handleNext={handleNext}
          setValue={setValue}
          trigger={trigger}
          getValues={getValues}
          register={register}
          watch={watch}
          control={control}
          handlePhoneChange={handlePhoneChange}
          addToDataLayer={addToDataLayer}
          setZipCode={setZipCode}
          isValid={isValid}
          zipCode={zipCode}
          isZipValid={isZipValid}
          canSubmit={canSubmit}
          setError={setError}
          clearErrors={clearErrors}
          handleCoverageNext={handleCoverageNext}
          handleGenderNext={handleGenderNext}
          handleConditionsNext={handleConditionsNext}
          handleCertUrlNext={handleCertUrlNext}
          onSubmit={handleSubmit(onSubmit)}
          loading={loading}
          certUrl={certUrl}
        />
      </div>
    </>
  );
}

export default App;
